import React, {Fragment} from 'react';
import {getFunName} from '../helpers';
import PropTypes from 'prop-types';

class StorePicker extends React.Component {

  static propTypes = {
    history: PropTypes.object,
  }

  myInput = React.createRef();

  goToStore = (e) => {
    // 1. Stop the form from submitting
    e.preventDefault();

    // 2. get the text from the input
    const storeName = this.myInput.current.value;
   
    // 3. Change the page to /store/whatever-they-entered
    this.props.history.push(`/store/${storeName}`);
  }

  render() {
    return (
      <Fragment>
        <form className="store-selector" onSubmit={this.goToStore}>
          <h2>Please Enter A Store</h2>
          <input
            type="text"
            ref={this.myInput}
            required
            placeholder="Store Name"
            defaultValue={getFunName()} 
          />
          <button type="submit">Visit Store</button>
        </form>
      </Fragment>
    )
  }
}

export default StorePicker;
